export default function contactForm() {
	return {
		fullName: '',
		email: '',
		phone: '',
		message: '',
		consent: false,
		errorMessage: '',
		successMessage: '',

		submitForm(e) {
			e.preventDefault()

			this.errorMessage = '';
			this.successMessage = '';

			// Validate the fields
			if (!this.fullName) {
				this.errorMessage = 'Full name is required.';
				return;
			}

			if (!this.email || !this.isValidEmail(this.email)) {
				this.errorMessage = 'Please enter a valid email address.';
				return;
			}

			if (!this.phone) {
				this.errorMessage = 'Phone number is required.';
				return;
			}

			if (!this.message) {
				this.errorMessage = 'Message is required.';
				return;
			}

			if (!this.consent) {
				this.errorMessage = 'You must agree to the terms and conditions.';
				return;
			}

			// If validation passes, show success message
			this.successMessage = 'Your message has been sent!'
			this.clearForm()
			e.target.submit()
		},

		// Clear the form after submission
		clearForm() {
			this.fullName = '';
			this.email = '';
			this.phone = '';
			this.message = '';
			this.consent = false;
		},

		isValidEmail(email) {
			const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			return emailPattern.test(email);
		}
	}
}
