/*###########################################################################
// Component
//#########################################################################*/

const themeSelect = {
	config: {
		themeSelect: '.themeSelect',
		themeSelectMinimise: '.themeSelect__minimise'
	},

	elements: {},

	init() {
		const self = this

		self.elements.themeSelect = document.querySelector(self.config.themeSelect)

		if (self.elements.themeSelect !== null) {
			self.handleThemeSelect()
			self.handleMinimise()
		}
	},

	handleThemeSelect() {
		const self = this

		self.elements.themeSelect.addEventListener('change', function() {
			const parser = new URL(window.location)
			parser.searchParams.set('theme', self.elements.themeSelect.value)
			window.location = parser.href
		})
	},

	handleMinimise() {
		const self = this

		const parentEl = self.elements.themeSelect.parentNode
		const minimiseToggle = self.elements.themeSelect.parentNode.querySelector(self.config.themeSelectMinimise)

		minimiseToggle.addEventListener('click', function() {
			if (!parentEl.classList.contains('-translate-x-[calc(50%+40px)]')) {
				parentEl.classList.add('-translate-x-[calc(50%+40px)]')
				minimiseToggle.innerText = '>'
			} else {
				parentEl.classList.remove('-translate-x-[calc(50%+40px)]')
				minimiseToggle.innerText = '<'
			}
		})
	}
}

window.addEventListener('load', function () {
	themeSelect.init()
})
