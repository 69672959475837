/*###########################################################################
// Height screen calc
//#########################################################################*/

import { throttle } from "../utilities/_throttleDebounce"

const hScreenCalc = {
  config: {
    hScreen: '.h-screen',
		minHScreen: '.min-h-screen'
  },

  elements: {},

  init() {
    const self = this

    self.elements.hScreenEls = document.querySelectorAll(self.config.hScreen)

    if (self.elements.hScreenEls !== null) {
      for (let i = 0; i < self.elements.hScreenEls.length; i++) {
        const hScreenEl = self.elements.hScreenEls[i]

        self.handleHScreen(hScreenEl)
      }
    }

		self.elements.minHScreenEls = document.querySelectorAll(self.config.minHScreen)

    if (self.elements.minHScreenEls !== null) {
      for (let i = 0; i < self.elements.minHScreenEls.length; i++) {
        const minHScreenEl = self.elements.minHScreenEls[i]

        self.handleMinHScreen(minHScreenEl)
      }
    }
  },

  handleHScreen(hScreenEl) {
    const self = this

    hScreenEl.style.height = ''
    hScreenEl.style.height = window.innerHeight + "px"

    window.addEventListener("resize", throttle(function() {
      hScreenEl.style.height = ''
      hScreenEl.style.height = window.innerHeight + "px"

      setTimeout(function() {
        hScreenEl.style.height = ''
        hScreenEl.style.height = window.innerHeight + "px"
      }, 500)
    }, 500))
  },

	handleMinHScreen(minHScreenEl) {
    const self = this

    minHScreenEl.style.minHeight = ''
    minHScreenEl.style.minHeight = window.innerHeight + "px"

    window.addEventListener("resize", throttle(function() {
      minHScreenEl.style.minHeight = ''
      minHScreenEl.style.minHeight = window.innerHeight + "px"

      setTimeout(function() {
        minHScreenEl.style.minHeight = ''
        minHScreenEl.style.minHeight = window.innerHeight + "px"
      }, 500)
    }, 500))
  }
}

window.addEventListener('load', function () {
  hScreenCalc.init()
})
